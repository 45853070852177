import {
  defaultDirection,
  defaultLocale,
  defaultColor,
  localeOptions,
  themeColorStorageKey,
  themeRadiusStorageKey
} from '../constants/defaultValues';
import jwt_decode from 'jwt-decode';
var decodeData;
export const mapOrder = (array, order, key) => {
  array.sort(function (a, b) {
    const A = a[key];
    const B = b[key];
    if (order.indexOf(`${A}`) > order.indexOf(`${B}`)) {
      return 1;
    }
    return -1;
  });
  return array;
};

export const getDateWithFormat = () => {
  const today = new Date();
  let dd = today.getDate();
  let mm = today.getMonth() + 1; // January is 0!

  const yyyy = today.getFullYear();
  if (dd < 10) {
    dd = `0${dd}`;
  }
  if (mm < 10) {
    mm = `0${mm}`;
  }
  return `${dd}.${mm}.${yyyy}`;
};

export const getCurrentTime = () => {
  const now = new Date();
  return `${now.getHours()}:${now.getMinutes()}`;
};

export const getDirection = () => {
  let direction = defaultDirection;

  try {
    if (localStorage.getItem('direction')) {
      const localValue = localStorage.getItem('direction');
      if (localValue === 'rtl' || localValue === 'ltr') {
        direction = localValue;
      }
    }
  } catch (error) {
    console.log(">>>>: src/helpers/Utils.js : getDirection -> error", error)
    direction = defaultDirection;
  }
  return {
    direction,
    isRtl: direction === 'rtl',
  };
};
export const setDirection = (localValue) => {
  let direction = 'ltr';
  if (localValue === 'rtl' || localValue === 'ltr') {
    direction = localValue;
  }
  try {
    localStorage.setItem('direction', direction);
  } catch (error) {
    console.log(">>>>: src/helpers/Utils.js : setDirection -> error", error)
  }
};

export const getCurrentColor = () => {
  let currentColor = defaultColor;
  try {
    if (localStorage.getItem(themeColorStorageKey)) {
      currentColor = localStorage.getItem(themeColorStorageKey);
    }
  } catch (error) {
    console.log(">>>>: src/helpers/Utils.js : getCurrentColor -> error", error)
    currentColor = defaultColor
  }
  return currentColor;
};

export const setCurrentColor = (color) => {
  try {
    localStorage.setItem(themeColorStorageKey, color);
  } catch (error) {
    console.log(">>>>: src/helpers/Utils.js : setCurrentColor -> error", error)
  }
};

export const getCurrentRadius = () => {
  let currentRadius = 'rounded';
  try {
    if (localStorage.getItem(themeRadiusStorageKey)) {
      currentRadius = localStorage.getItem(themeRadiusStorageKey);
    }
  } catch (error) {
    console.log(">>>>: src/helpers/Utils.js : getCurrentRadius -> error", error)
    currentRadius = 'rounded'
  }
  return currentRadius;
};
export const setCurrentRadius = (radius) => {
  try {
    localStorage.setItem(themeRadiusStorageKey, radius);
  } catch (error) {
    console.log(">>>>: src/helpers/Utils.js : setCurrentRadius -> error", error)
  }
};

export const getCurrentLanguage = () => {
  let language = defaultLocale;
  try {
    language = localStorage.getItem('currentLanguage') &&
      localeOptions.filter(
        (x) => x.id === localStorage.getItem('currentLanguage')
      ).length > 0
      ? localStorage.getItem('currentLanguage')
      : defaultLocale;
  } catch (error) {
    console.log(">>>>: src/helpers/Utils.js : getCurrentLanguage -> error", error)
    language = defaultLocale;
  }
  return language;
};
export const setCurrentLanguage = (locale) => {
  try {
    localStorage.setItem('currentLanguage', locale);
  } catch (error) {
    console.log(">>>>: src/helpers/Utils.js : setCurrentLanguage -> error", error)
  }
};

export const getCurrentUser = () => {
  let user = null;
  try {
    user = localStorage.getItem('role') != null ? localStorage.getItem('role') : null;
  } catch (error) {
    console.log(">>>>: src/helpers/Utils.js  : getCurrentUser -> error", error)
    user = null;
  }
  return user;
}

export const setCurrentUser = (token) => {

  //   aud: "http://localhost:51740"
  // avatar: "https://prosperfleet.blob.core.windows.net/assets/avatar.png"
  // email: "ali.ghauri@intelligenes.net"
  // exp: 1606307532
  // firstname: "Ali"
  // http://schemas.microsoft.com/ws/2008/06/identity/claims/role: "SuperAdministrator"
  // iss: "http://localhost:51740"
  // jti: "d518fdbe-6e39-4d7b-9783-94d4f9e21831"
  // lastname: "Ghauri"
  // role: "SuperAdministrator"
  // sub: "ali.ghauri@intelligenes.net"

  if (token) {
    localStorage.setItem("token", token);
    decodeData = jwt_decode(token);
    // console.log(decodeData,"this is decide data")
    localStorage.setItem("role", decodeData.role);
    localStorage.setItem("email", decodeData.email);
    localStorage.setItem("firstname", decodeData.firstname);
    localStorage.setItem("lastname", decodeData.lastname);
    localStorage.setItem("avatar", decodeData.avatar)
  } else {

    localStorage.removeItem("token");
    localStorage.removeItem("role");
    localStorage.removeItem("email");
    localStorage.removeItem("firstname");
    localStorage.removeItem("lastname");
    localStorage.removeItem("avatar")

  }

  // localStorage.setItem("user_name", decodeData.given_name);
  // localStorage.setItem("user_email", decodeData.email);
  //localStorage.setItem('gogo_current_user', JSON.stringify(user))



};


export const thousands_separators = (num) => {
  if (num != null) {
    var num_parts = num.toString().split('.');
    num_parts[0] = num_parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    return num_parts.join('.');
  }
};